import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import FeedViewer from './pages/FeedViewer';
import FeedAudit from './pages/FeedAudit';
import LoginPage from './pages/LoginPage';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const clientId = "329886403315-60onbb9j4l30fe5v9ml1smhgdqdif3c8.apps.googleusercontent.com"; // Your Google Client ID

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <Routes>
          {/* Root URL ("/") should navigate to FeedViewer without a feedId */}
          <Route path="/" element={<FeedViewer />} />

          {/* Main page (FeedViewer) with dynamic feedId */}
          <Route 
            path="/feedviewer/:feedId" 
            element={<FeedViewer />} 
          />

          {/* FeedAudit page with dynamic feedId */}
          <Route 
            path="/feedaudit/:feedId" 
            element={<FeedAudit />} 
          />

          {/* Route to LoginPage */}
          <Route path="/login" element={<LoginPage />} />

          {/* Redirect all unknown routes to the root URL */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
