import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../css/FeedAudit.module.css';
import { FaDownload, FaCheck, FaArrowLeft, FaSearch, FaTimes} from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';
import * as XLSX from 'xlsx'; // Import XLSX


function FeedAudit() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { feedId } = useParams();
  const navigate = useNavigate(); 
  const [titleAuditResults, setTitleAuditResults] = useState({});
  const [descriptionAuditResults, setDescriptionAuditResults] = useState({});
  const [loadingTitle, setLoadingTitle] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('english');
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [colorInput, setColorInput] = useState('');
  const [brandInput, setBrandInput] = useState('');
  const [sizeInput, setSizeInput] = useState('');
  const [genderInput, setGenderInput] = useState('male, female, unisex');
  const [ageGroupInput, setAgeGroupInput] = useState('newborn, infant, toddler, kids, adult');
  const [patternInput, setPatternInput] = useState('');
  const [materialInput, setMaterialInput] = useState('');
  const [auditStarted, setAuditStarted] = useState(false); // New state
  const [attributeAuditResults, setAttributeAuditResults] = useState({});
  const [loadingAttributes, setLoadingAttributes] = useState(false);
  const exceedsLimit = (input) => countInputs(input) > 2000;
  const [popupData, setPopupData] = useState(null); // Existing state for attribute popup
  const [randomSamplePopupData, setRandomSamplePopupData] = useState(null); // New state for random sample popup  
  const [attributeView, setAttributeView] = useState('as_is');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc', view: 'as_is' }); 
  const [customSelectedAttributes, setCustomSelectedAttributes] = useState([]);

  const merchantCenterOrder = [
    'item_group_id',
    'id',
    'gtin',
    'title',
    'description',
    'brand',
    'availability',
    'price',
    'size',
    'pattern',
    'material',
    'color',
    'gender',
    'age_group',
    'google_product_category',
    'product_type',
    'product_detail',
    'image_link',
    'additional_image_link',
    'lifestyle_image_link',
    'product_highlight',
    'product_page_url',
    'link'
];

const attributeOptions = Object.keys(attributeAuditResults).map(attribute => ({
  value: attribute,
  label: attribute
}));


const handleCustomViewChange = selectedOptions => {
  setCustomSelectedAttributes(selectedOptions || []);
};

  const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  
  const loadFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };

  const countInputs = (inputString) => {
    return inputString.split(',').map(item => item.trim()).filter(item => item !== '').length;
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, view: attributeView }); // Save the current view along with sort config
  };
  
  const sortData = (data) => {
    if (!sortConfig.key) return data;
  
    const sortedData = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  };
  

  const sortedAttributes = sortData(Object.keys(attributeAuditResults).map((attribute) => {
    const topValues = attributeAuditResults[attribute]?.top_values || [];
    const uniqueValuesCount = topValues.length;
    return {
      attribute,
      percentage: attributeAuditResults[attribute]?.percentage,
      not_empty: attributeAuditResults[attribute]?.not_empty,
      topValue: topValues.length > 0 ? topValues[0][0] : '',
      topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
      uniqueValuesCount,
    };
  }));
  
  const getDefaultSortedData = (data) => {
    if (attributeView === 'merchant_center') {
      return data.sort((a, b) => merchantCenterOrder.indexOf(a.attribute) - merchantCenterOrder.indexOf(b.attribute));
    }
    return data;
  };
  

  const isAnyInputExceedsLimit = () => {
    return (
      countInputs(colorInput) > 2000 ||
      countInputs(brandInput) > 2000 ||
      countInputs(sizeInput) > 2000 ||
      countInputs(genderInput) > 2000 ||
      countInputs(ageGroupInput) > 2000 ||
      countInputs(patternInput) > 2000 ||
      countInputs(materialInput) > 2000
    );
  };

  const openPopup = (attribute) => {
    setPopupData({
      attribute: attribute,
      topValues: attributeAuditResults[attribute].top_values
    });
  };

  const predefinedColors = {
    english: [
      'black', 'white', 'red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown',
      'gray', 'grey', 'gold', 'silver', 'beige', 'ivory', 'maroon', 'navy', 'violet',
      'turquoise', 'peach', 'teal', 'coral', 'lavender', 'mustard', 'burgundy', 'cyan',
      'magenta', 'mint', 'olive', 'salmon', 'tan', 'charcoal', 'indigo', 'plum', 'amber'
    ],
    danish: [
      'sort', 'hvid', 'rød', 'blå', 'grøn', 'gul', 'orange', 'lilla', 'lyserød', 'brun',
      'grå', 'sølv', 'guld', 'beige', 'elfenben', 'bordeaux', 'marineblå', 'violet',
      'turkis', 'fersken', 'teal', 'koraller', 'lavendel', 'sennep', 'vinrød', 'cyan',
      'magenta', 'mynte', 'oliven', 'laks', 'tan', 'kul', 'indigo', 'blomme', 'rav'
    ]
  };

  const fetchTitleAuditResults = async (colors, brands, sizes, genders, ageGroups, patterns, materials) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/feed-audit/titles`, {
        feed_id: feedId,
        colors: colors,
        brands: brands,
        sizes: sizes,
        genders: genders,
        age_groups: ageGroups,
        patterns: patterns,
        materials: materials
      });
      setTitleAuditResults(response.data);
      saveToLocalStorage(`${feedId}_titleAuditResults`, response.data); // Save title audit results to local storage
    } catch (error) {
      setError('Failed to fetch title audit results.');
    } finally {
      setLoadingTitle(false);
    }
  };

  const fetchDescriptionAuditResults = async (colors, brands, sizes, genders, ageGroups, patterns, materials) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/feed-audit/descriptions`, {
        feed_id: feedId,
        colors: colors,
        brands: brands,
        sizes: sizes,
        genders: genders,
        age_groups: ageGroups,
        patterns: patterns,
        materials: materials
      });
      setDescriptionAuditResults(response.data);
      saveToLocalStorage(`${feedId}_descriptionAuditResults`, response.data); // Save description audit results to local storage
    } catch (error) {
      setError('Failed to fetch description audit results.');
    } finally {
      setLoadingDescription(false);
    }
  };


const fetchAttributeAuditResults = async () => {
  setLoadingAttributes(true);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/feed-audit/attributes`, { feed_id: feedId });
    setAttributeAuditResults(response.data);
    console.log('Attribute Audit Results:', response.data); // Debugging line
    saveToLocalStorage(`${feedId}_attributeAuditResults`, response.data); // Save attribute audit results to local storage
  } catch (error) {
    setError('Failed to fetch attribute audit results.');
  } finally {
    setLoadingAttributes(false);
  }
};

  
  

  const fetchBrandsAndSizes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/get-brands-and-sizes?feed_id=${feedId}`);
      const brands = response.data.brands;
      const sizes = response.data.sizes;
      const patterns = response.data.patterns;
      const materials = response.data.materials;

      setBrandInput(brands.join(', '));
      setSizeInput(sizes.join(', '));
      setPatternInput(patterns.join(', '));
      setMaterialInput(materials.join(', '));
    } catch (error) {
      setError('Failed to fetch brands and sizes.');
    }
  };

  const getColorForPercentage = (value) => {
    if (value === undefined || value === null) {
      return '#000'; // Fallback color if value is not provided
    }
  
    const darkerGreen = { r: 76, g: 175, b: 80 };
    const yellow = { r: 255, g: 235, b: 59 };
    const red = { r: 235, g: 71, b: 85 };
  
    if (value <= 50) {
      const r = Math.round(red.r + ((yellow.r - red.r) * (value / 50)));
      const g = Math.round(red.g + ((yellow.g - red.g) * (value / 50)));
      const b = Math.round(red.b + ((yellow.b - red.b) * (value / 50)));
      return `rgb(${r}, ${g}, ${b})`;
    } else {
      const r = Math.round(yellow.r + ((darkerGreen.r - yellow.r) * ((value - 50) / 50)));
      const g = Math.round(yellow.g + ((darkerGreen.g - yellow.g) * ((value - 50) / 50)));
      const b = Math.round(yellow.b + ((darkerGreen.b - yellow.b) * ((value - 50) / 50)));
      return `rgb(${r}, ${g}, ${b})`;
    }
  };
  

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setPopupData(null);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const initialColors = predefinedColors[selectedLanguage].join(', ');
    setColorInput(initialColors);
  }, [selectedLanguage]);


  useEffect(() => {
    setSortConfig({ key: null, direction: 'asc', view: attributeView }); // Reset sort config when view changes
  }, [attributeView]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupData && !document.getElementById('popupContent').contains(event.target)) {
        setPopupData(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupData]);

  useEffect(() => {
    const handleClickOutsideRandomSamplePopup = (event) => {
      if (randomSamplePopupData && !document.getElementById('randomSamplePopupContent').contains(event.target)) {
        setRandomSamplePopupData(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutsideRandomSamplePopup);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRandomSamplePopup);
    };
  }, [randomSamplePopupData]);

  useEffect(() => {
    const savedData = loadFromLocalStorage(feedId);
    
    if (savedData) {
        setColorInput(savedData.colorInput || '');
        setBrandInput(savedData.brandInput || '');
        setSizeInput(savedData.sizeInput || '');
        setGenderInput(savedData.genderInput || '');
        setAgeGroupInput(savedData.ageGroupInput || '');
        setPatternInput(savedData.patternInput || '');
        setMaterialInput(savedData.materialInput || '');
        setAttributeView(savedData.attributeView || 'as_is'); // Restore the saved view or default to "As Is"
        
        // Restore the saved custom columns and ensure they are in the correct format
        const restoredCustomAttributes = savedData.customSelectedAttributes?.map(attr => ({
            value: attr.value,
            label: attr.label
        })) || [];

        setCustomSelectedAttributes(restoredCustomAttributes); // Restore the saved custom columns
    } else {
        fetchBrandsAndSizes();
    }
}, [feedId]);



  useEffect(() => {
    const savedTitleAuditResults = loadFromLocalStorage(`${feedId}_titleAuditResults`);
    const savedDescriptionAuditResults = loadFromLocalStorage(`${feedId}_descriptionAuditResults`);
    const savedAttributeAuditResults = loadFromLocalStorage(`${feedId}_attributeAuditResults`); // Load attribute audit results from local storage
    
    if (savedTitleAuditResults && savedDescriptionAuditResults && savedAttributeAuditResults) {
      setTitleAuditResults(savedTitleAuditResults);
      setDescriptionAuditResults(savedDescriptionAuditResults);
      setAttributeAuditResults(savedAttributeAuditResults); // Set the loaded attribute audit results
      setAuditStarted(true);
    } else {
      fetchBrandsAndSizes();
    }
  }, [feedId]);
  
  

  const handleApply = () => {
    if (isAnyInputExceedsLimit()) {
        alert('One or more inputs exceed the limit of 2000 items. Please reduce the number of items before proceeding.');
        return;
    }

    // Save the current state to local storage, including the view and custom selected attributes
    saveToLocalStorage(feedId, {
        colorInput,
        brandInput,
        sizeInput,
        genderInput,
        ageGroupInput,
        patternInput,
        materialInput,
        attributeView, // Save the selected view
        customSelectedAttributes, // Save the selected columns for the custom view
    });

    setAuditStarted(true);
    setLanguage(selectedLanguage);
    setLoadingTitle(true);
    setLoadingDescription(true);
    setLoadingAttributes(true);

    const colorsArray = colorInput.split(',').map(color => color.trim());
    const brandsArray = brandInput.split(',').map(brand => brand.trim());
    const sizesArray = sizeInput.split(',').map(size => size.trim());
    const gendersArray = genderInput.split(',').map(gender => gender.trim());
    const ageGroupsArray = ageGroupInput.split(',').map(ageGroup => ageGroup.trim());
    const patternsArray = patternInput.split(',').map(pattern => pattern.trim());
    const materialsArray = materialInput.split(',').map(material => material.trim());

    fetchTitleAuditResults(colorsArray, brandsArray, sizesArray, gendersArray, ageGroupsArray, patternsArray, materialsArray);
    fetchDescriptionAuditResults(colorsArray, brandsArray, sizesArray, gendersArray, ageGroupsArray, patternsArray, materialsArray);
    fetchAttributeAuditResults();
};

  
const openRandomSamplePopup = async (type, interval) => {
  try {
    const apiUrl =
      type === 'title'
        ? `${API_BASE_URL}/api/feed-audit/random-samples-title`
        : `${API_BASE_URL}/api/feed-audit/random-samples-description`;

    const response = await axios.post(apiUrl, {
      feed_id: feedId,
      interval: interval,
    });

    const intervalLabels = {
      'empty': 'Empty',
      'length_1_24': '1 and 24',
      'length_25_49': '25 and 49',
      'length_50_74': '50 and 74',
      'length_75_plus': '75 or more',
      'length_1_100': '1 and 99',
      'length_101_500': '100 and 499',
      'length_501_1500': '500 and 1499',
      'length_1500_plus': '1500 or more',
    };

    setRandomSamplePopupData({
      attribute: `${type.charAt(0).toUpperCase() + type.slice(1)} between ${intervalLabels[interval]}`,
      topValues: response.data.samples,
      type, // Make sure to pass `type` here
    });
    
  } catch (error) {
    setError('Failed to fetch random samples.');
  }
};




  

  const formatPercentage = (value) => {
    return value !== undefined && value !== null ? value.toFixed(1) + '%' : 'N/A';
  };

  const handleInputChange = (setter, value) => {
    setter(value);
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();

    // Helper function to replace undefined/null with 0
    const sanitizeValue = (value) => (value === undefined || value === null ? 0 : value);

    // Add Title Audit sheet
    const titleAuditData = [
      ['Avg. Length', 'Empty', '1-24', '25-49', '50-74', '>75', 'Color', 'Gender', 'Age Group', 'Brand', 'Size', 'Pattern', 'Material'],
      [
        sanitizeValue(titleAuditResults?.average_title_length),
        sanitizeValue(titleAuditResults?.title_null_or_zero),
        sanitizeValue(titleAuditResults?.title_length_1_24),
        sanitizeValue(titleAuditResults?.title_length_25_49),
        sanitizeValue(titleAuditResults?.title_length_50_74),
        sanitizeValue(titleAuditResults?.title_length_75_plus),
        sanitizeValue(titleAuditResults?.appearing_title_color),
        sanitizeValue(titleAuditResults?.appearing_title_gender),
        sanitizeValue(titleAuditResults?.appearing_title_age_group),
        sanitizeValue(titleAuditResults?.appearing_title_brand),
        sanitizeValue(titleAuditResults?.appearing_title_size),
        sanitizeValue(titleAuditResults?.appearing_title_pattern),
        sanitizeValue(titleAuditResults?.appearing_title_material),
      ],
      [
        '', // Empty cell for alignment
        sanitizeValue(titleAuditResults?.title_null_or_zero_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_1_24_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_25_49_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_50_74_percentage) + '%',
        sanitizeValue(titleAuditResults?.title_length_75_plus_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_color_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_gender_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_age_group_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_brand_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_size_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_pattern_percentage) + '%',
        sanitizeValue(titleAuditResults?.appearing_title_material_percentage) + '%',
      ],
    ];
    const wsTitle = XLSX.utils.aoa_to_sheet(titleAuditData);
    XLSX.utils.book_append_sheet(wb, wsTitle, 'Title Audit');

    // Add Description Audit sheet
    const descriptionAuditData = [
      ['Avg. Length', 'Empty', '1-99', '100-500', '501-1500', '>1500', 'Color', 'Gender', 'Age Group', 'Brand', 'Size', 'Pattern', 'Material'],
      [
        sanitizeValue(descriptionAuditResults?.average_description_length),
        sanitizeValue(descriptionAuditResults?.description_null_or_zero),
        sanitizeValue(descriptionAuditResults?.description_length_1_100),
        sanitizeValue(descriptionAuditResults?.description_length_101_500),
        sanitizeValue(descriptionAuditResults?.description_length_501_1500),
        sanitizeValue(descriptionAuditResults?.description_length_1500_plus),
        sanitizeValue(descriptionAuditResults?.appearing_description_color),
        sanitizeValue(descriptionAuditResults?.appearing_description_gender),
        sanitizeValue(descriptionAuditResults?.appearing_description_age_group),
        sanitizeValue(descriptionAuditResults?.appearing_description_brand),
        sanitizeValue(descriptionAuditResults?.appearing_description_size),
        sanitizeValue(descriptionAuditResults?.appearing_description_pattern),
        sanitizeValue(descriptionAuditResults?.appearing_description_material),
      ],
      [
        '', // Empty cell for alignment
        sanitizeValue(descriptionAuditResults?.description_null_or_zero_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_1_100_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_101_500_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_501_1500_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.description_length_1500_plus_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_color_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_gender_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_age_group_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_brand_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_size_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_pattern_percentage) + '%',
        sanitizeValue(descriptionAuditResults?.appearing_description_material_percentage) + '%',
      ],
    ];
    const wsDescription = XLSX.utils.aoa_to_sheet(descriptionAuditData);
    XLSX.utils.book_append_sheet(wb, wsDescription, 'Desc. Audit');

    // Add Attribute Audit (as is) sheet
    const attributeAuditAsIsData = [
      ['Attribute', 'Coverage %', 'Count #', 'Most Frequent (Sample)'],
      ...Object.keys(attributeAuditResults).map(attr => [
        attr,
        sanitizeValue(attributeAuditResults[attr]?.percentage) + '%',
        sanitizeValue(attributeAuditResults[attr]?.not_empty),
        sanitizeValue(attributeAuditResults[attr]?.top_values?.[0]?.[0]),
      ]),
    ];
    const wsAttributesAsIs = XLSX.utils.aoa_to_sheet(attributeAuditAsIsData);
    XLSX.utils.book_append_sheet(wb, wsAttributesAsIs, 'Attrib. (as is)');

    // Add Attribute Audit (merchant center) sheet
    const merchantCenterData = [
      ['Attribute', 'Coverage %', 'Count #', 'Most Frequent (Sample)'],
      ...merchantCenterOrder.map(attr => {
        const attrData = attributeAuditResults[attr];
        return [
          attr,
          sanitizeValue(attrData?.percentage) + '%',
          sanitizeValue(attrData?.not_empty),
          sanitizeValue(attrData?.top_values?.[0]?.[0]),
        ];
      }),
    ];
    const wsAttributesMerchantCenter = XLSX.utils.aoa_to_sheet(merchantCenterData);
    XLSX.utils.book_append_sheet(wb, wsAttributesMerchantCenter, 'Attrib. (MC)');

    // Add Attribute Audit (custom) sheet
    const customAttributesData = [
      ['Attribute', 'Coverage %', 'Count #', 'Most Frequent (Sample)'],
      ...customSelectedAttributes.map(attr => [
        attr.label,
        sanitizeValue(attributeAuditResults[attr.value]?.percentage) + '%',
        sanitizeValue(attributeAuditResults[attr.value]?.not_empty),
        sanitizeValue(attributeAuditResults[attr.value]?.top_values?.[0]?.[0]),
      ]),
    ];
    const wsAttributesCustom = XLSX.utils.aoa_to_sheet(customAttributesData);
    XLSX.utils.book_append_sheet(wb, wsAttributesCustom, 'Attrib. (custom)');

    // Add Items Found sheet
    const itemsFoundData = [['Total Items Found', sanitizeValue(titleAuditResults?.total_titles)]];
    const wsItemsFound = XLSX.utils.aoa_to_sheet(itemsFoundData);
    XLSX.utils.book_append_sheet(wb, wsItemsFound, 'Items Found');

    // Trigger file download
    XLSX.writeFile(wb, 'FeedAuditResults.xlsx');
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.topBar}>
        <button 
            onClick={() => navigate(`/feedviewer/${feedId}`)} 
            className={styles.backButton}
        >
            <FaArrowLeft size={20} />
        </button>

        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>Feed Viewer 2.0</span>
          <span className={styles.headerSubtitle}>Feed Audit (WORK IN PROGRESS)</span>
        </div>
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header Image"
          className={styles.headerImage}
        />
      </div>
      <div className={styles.content}>
      <div className={styles.configurationContainer}>
  <p><b>Based on Predefined Values:</b></p>
  
  <div className={styles.languageAndColorContainer}>
    <div className={styles.languageSelector}>
      <label htmlFor="colorInput">
        Colors <span className={exceedsLimit(colorInput) ? styles.warningMessage : ''}>
          (#{countInputs(colorInput)})
        </span>
        :
      </label>
      <select
        id="language"
        value={selectedLanguage}
        onChange={(e) => setSelectedLanguage(e.target.value)}
      >
        <option value="english">English</option>
        <option value="danish">Danish</option>
      </select>
    </div>

    <div className={styles.colorInputContainer}>
      <input
        type="text"
        id="colorInput"
        value={colorInput}
        onChange={(e) => handleInputChange(setColorInput, e.target.value)}
        className={styles.colorInput}
      />
    </div>
  </div>

  <div className={styles.inputContainer}>
    <label htmlFor="genderInput">
      Genders <span className={exceedsLimit(genderInput) ? styles.warningMessage : ''}>
        (#{countInputs(genderInput)})
      </span>
      :
    </label>
    <input
      type="text"
      id="genderInput"
      value={genderInput}
      onChange={(e) => handleInputChange(setGenderInput, e.target.value)}
      className={styles.inputField}
    />
  </div>

  <div className={styles.inputContainer}>
    <label htmlFor="ageGroupInput">
      Age Groups <span className={exceedsLimit(ageGroupInput) ? styles.warningMessage : ''}>
        (#{countInputs(ageGroupInput)})
      </span>
      :
    </label>
    <input
      type="text"
      id="ageGroupInput"
      value={ageGroupInput}
      onChange={(e) => handleInputChange(setAgeGroupInput, e.target.value)}
      className={styles.inputField}
    />
  </div>

  <hr className={styles.divider} />

  <p><b>Based on Feed Values:</b></p>

  <div className={styles.inputContainer}>
    <label htmlFor="brandInput">
      Brands <span className={exceedsLimit(brandInput) ? styles.warningMessage : ''}>
        (#{countInputs(brandInput)})
      </span>
      :
    </label>
    <input
      type="text"
      id="brandInput"
      value={brandInput}
      onChange={(e) => handleInputChange(setBrandInput, e.target.value)}
      className={styles.inputField}
    />
  </div>

  <div className={styles.inputContainer}>
    <label htmlFor="sizeInput">
      Sizes <span className={exceedsLimit(sizeInput) ? styles.warningMessage : ''}>
        (#{countInputs(sizeInput)})
      </span>
      :
    </label>
    <input
      type="text"
      id="sizeInput"
      value={sizeInput}
      onChange={(e) => handleInputChange(setSizeInput, e.target.value)}
      className={styles.inputField}
    />
  </div>

  <div className={styles.inputContainer}>
    <label htmlFor="patternInput">
      Patterns <span className={exceedsLimit(patternInput) ? styles.warningMessage : ''}>
        (#{countInputs(patternInput)})
      </span>
      :
    </label>
    <input
      type="text"
      id="patternInput"
      value={patternInput}
      onChange={(e) => handleInputChange(setPatternInput, e.target.value)}
      className={styles.inputField}
    />
  </div>

  <div className={styles.inputContainer}>
    <label htmlFor="materialInput">
      Materials <span className={exceedsLimit(materialInput) ? styles.warningMessage : ''}>
        (#{countInputs(materialInput)})
      </span>
      :
    </label>
    <input
      type="text"
      id="materialInput"
      value={materialInput}
      onChange={(e) => handleInputChange(setMaterialInput, e.target.value)}
      className={styles.inputField}
    />
  </div>

            {!isAnyInputExceedsLimit() && (
              <div className={styles.buttonContainer}>
                <button 
                  onClick={handleApply} 
                  className={styles.applyFilterButton} 
                  disabled={loadingTitle || loadingDescription || loadingAttributes} // Disabled if any loading is in progress
                >
                  {(loadingTitle || loadingDescription || loadingAttributes) ? <ClipLoader size={20} color="#ffffff" /> : <FaCheck />}
                  {(loadingTitle || loadingDescription || loadingAttributes) ? null : " Apply"}
                </button>

                <button 
                  onClick={handleDownload} 
                  className={styles.downloadButton} 
                  disabled={!auditStarted} // Disabled until a feed is successfully audited
                >
                  <FaDownload />
                </button>
              </div>
        )}
          
          {isAnyInputExceedsLimit() && (
            <p className={styles.warningMessage}>⚠️ Max 2000 labels: This audit is currently blocked due to the high number of labels provided.</p>
          )}

        </div>

        {error ? (
          <p className={styles.error}>{error}</p>
        ) : (
          <>
           
           
           
           
           
           
           
           {auditStarted && titleAuditResults && (
  <div className={styles.auditResult}>
    {/* Count of Products */}
    <div className={styles.countOfProductsContainer}>
      <p className={styles.countOfProducts}>
        {loadingTitle ? <ClipLoader size={20} /> : `${titleAuditResults.total_titles} Items Found`}
      </p>
    </div>

    {/* Title Length Audit */}
    <h3 className={styles.auditTitle}>Title</h3>
    <table className={styles.auditTable}>
      <thead>
        <tr>
          <th colSpan="6">Length Audit</th>
          <th colSpan="7">Found in Title</th>
        </tr>
        <tr>
          <th>Avg. Length</th>
          <th>Empty</th>
          <th>
            1-24
            <button onClick={() => openRandomSamplePopup('title', 'length_1_24')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>
            25-49
            <button onClick={() => openRandomSamplePopup('title', 'length_25_49')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>
            50-74
            <button onClick={() => openRandomSamplePopup('title', 'length_50_74')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>
            =&gt;75
            <button onClick={() => openRandomSamplePopup('title', 'length_75_plus')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>Color</th>
          <th>Gender</th>
          <th>Age Group</th>
          <th>Brand</th>
          <th>Size</th>
          <th>Pattern</th>
          <th>Material</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{loadingTitle ? <ClipLoader size={20} /> : `${titleAuditResults?.average_title_length} characters`}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_null_or_zero}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_1_24}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_25_49}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_50_74}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.title_length_75_plus}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_color}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_gender}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_age_group}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_brand}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_size}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_pattern}</td>
          <td>{loadingTitle ? <ClipLoader size={20} /> : titleAuditResults?.appearing_title_material}</td>
        </tr>
        <tr>
          <td></td> {/* Placeholder cell for percentage row alignment */}
          <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_null_or_zero_percentage)}</span></td>
          <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_1_24_percentage)}</span></td>
          <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_25_49_percentage)}</span></td>
          <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_50_74_percentage)}</span></td>
          <td><span>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.title_length_75_plus_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_color_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_color_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_gender_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_gender_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_age_group_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_age_group_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_brand_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_brand_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_size_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_size_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_pattern_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_pattern_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(titleAuditResults?.appearing_title_material_percentage) }}>{loadingTitle ? <ClipLoader size={10} /> : formatPercentage(titleAuditResults?.appearing_title_material_percentage)}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
)}

{auditStarted && descriptionAuditResults && (
  <div className={styles.auditResult}>
    {/* Description Length Audit */}
    <h3 className={styles.auditTitle}>Description</h3>
    <table className={styles.auditTable}>
      <thead>
        <tr>
          <th colSpan="6">Length Audit</th>
          <th colSpan="7">Found in Description</th>
        </tr>
        <tr>
          <th>Avg. Length</th>
          <th>Empty</th>
          <th>
            1-99
            <button onClick={() => openRandomSamplePopup('description', 'length_1_100')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>
            100-499
            <button onClick={() => openRandomSamplePopup('description', 'length_101_500')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>
            500-1499
            <button onClick={() => openRandomSamplePopup('description', 'length_501_1500')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>
            =&gt;1500
            <button onClick={() => openRandomSamplePopup('description', 'length_1500_plus')} className={styles.smallTableButton}>
              <FaSearch />
            </button>
          </th>
          <th>Color</th>
          <th>Gender</th>
          <th>Age Group</th>
          <th>Brand</th>
          <th>Size</th>
          <th>Pattern</th>
          <th>Material</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{loadingDescription ? <ClipLoader size={20} /> : `${descriptionAuditResults?.average_description_length} characters`}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_null_or_zero}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_1_100}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_101_500}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_501_1500}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.description_length_1500_plus}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_color}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_gender}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_age_group}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_brand}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_size}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_pattern}</td>
          <td>{loadingDescription ? <ClipLoader size={20} /> : descriptionAuditResults?.appearing_description_material}</td>
        </tr>
        <tr>
          <td></td> {/* Placeholder cell for percentage row alignment */}
          <td><span>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_null_or_zero_percentage)}</span></td>
          <td><span>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_1_100_percentage)}</span></td>
          <td><span>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_101_500_percentage)}</span></td>
          <td><span>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_501_1500_percentage)}</span></td>
          <td><span>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.description_length_1500_plus_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_color_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_color_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_gender_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_gender_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_age_group_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_age_group_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_brand_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_brand_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_size_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_size_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_pattern_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_pattern_percentage)}</span></td>
          <td className="appearing-attribute"><span style={{ color: getColorForPercentage(descriptionAuditResults?.appearing_description_material_percentage) }}>{loadingDescription ? <ClipLoader size={10} /> : formatPercentage(descriptionAuditResults?.appearing_description_material_percentage)}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
)}




{auditStarted && attributeAuditResults && (
    <div className={styles.auditResult}>
          <div className={styles.auditHeader}>
          <h3 className={styles.auditTitle}>Attribute Audit</h3>
          <Select
            value={{ value: attributeView, label: attributeView.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
            onChange={(selectedOption) => setAttributeView(selectedOption.value)}
            options={[
                { value: 'as_is', label: 'As is' },
                { value: 'merchant_center', label: 'Merchant center' },
                { value: 'custom', label: 'Custom View' }
            ]}
            className={styles.attributeDropdown}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#f8f8f8',
                borderColor: '#ccc',
                color: '#292B3D',
                '&:hover': { borderColor: '#4758EB' },
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#ffffff',
                color: '#292B3D',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#848EE0' : state.isFocused ? '#e0e1eb' : '#ffffff',
                color: state.isSelected ? '#ffffff' : '#292B3D',
                '&:hover': {
                  backgroundColor: '#4758EB',
                  color: '#ffffff',
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#292B3D',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#888',
              }),
            }}
          />
            
            {attributeView === 'custom' && (
              <Select
                isMulti
                options={attributeOptions}
                onChange={handleCustomViewChange}
                className={styles.customSelect}
                placeholder="Select attributes..."
                closeMenuOnSelect={false}
              />
            )}
          </div>
        <table className={styles.attributeAuditTable}>
          <thead>
              <tr>
                  <th className={styles.attributeColumn} onClick={() => handleSort('attribute')}>
                      {loadingAttributes ? <ClipLoader size={20} /> : 'Attribute'}
                  </th>
                  <th className={styles.smallColumn} onClick={() => handleSort('percentage')}>
                      {loadingAttributes ? <ClipLoader size={20} /> : 'Coverage %'}
                  </th>
                  <th className={styles.smallColumn} onClick={() => handleSort('not_empty')}>
                      {loadingAttributes ? <ClipLoader size={20} /> : 'Count #'}
                  </th>
                  <th className={styles.frequentColumn} onClick={() => handleSort('topValue')}>
                      {loadingAttributes ? <ClipLoader size={20} /> : 'Most Frequent (Sample)'}
                  </th>
              </tr>
          </thead>

            <tbody>
                {attributeView === 'as_is' &&
                    sortData(getDefaultSortedData(
                        Object.keys(attributeAuditResults).map((attribute) => {
                            const topValues = attributeAuditResults[attribute]?.top_values || [];
                            const uniqueValuesCount = topValues.length;
                            return {
                                attribute,
                                percentage: attributeAuditResults[attribute]?.percentage,
                                not_empty: attributeAuditResults[attribute]?.not_empty,
                                topValue: topValues.length > 0 ? topValues[0][0] : '',
                                topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
                                uniqueValuesCount,
                            };
                        })
                    )).map((attr) => (
                        <tr key={attr.attribute}>
                            <td className={styles.attributeColumn}>{loadingAttributes ? <ClipLoader size={20} /> : attr.attribute}</td>
                            <td className={styles.smallColumn} style={{ color: getColorForPercentage(attr.percentage) }}>
                                {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                            </td>
                            <td className={styles.smallColumn}>
                                {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                            </td>
                            <td className={styles.frequentColumn}>
                                {loadingAttributes ? <ClipLoader size={20} /> : (
                                    <>
                                        {attr.topValue.length > 50 ? `${attr.topValue.substring(0, 50)}...` : attr.topValue} ({attr.topValueCount})
                                        {attr.uniqueValuesCount > 1 && (
                                            <button onClick={() => openPopup(attr.attribute)} className={styles.moreButton}>
                                                <FaSearch />
                                            </button>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))
                }

                {attributeView === 'merchant_center' &&
                    sortData(getDefaultSortedData(
                        merchantCenterOrder.map((merchantAttr) => {
                            const attributeData = attributeAuditResults[merchantAttr];
                            if (!attributeData) {
                                return {
                                    attribute: merchantAttr,
                                    percentage: 0,
                                    not_empty: 0,
                                    topValue: 'N/A',
                                    topValueCount: 0,
                                    uniqueValuesCount: 0,
                                };
                            }

                            const topValues = attributeData.top_values || [];
                            const uniqueValuesCount = topValues.length;

                            return {
                                attribute: merchantAttr,
                                percentage: attributeData.percentage,
                                not_empty: attributeData.not_empty,
                                topValue: topValues.length > 0 ? topValues[0][0] : '',
                                topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
                                uniqueValuesCount,
                            };
                        })
                    )).map((attr) => (
                        <tr key={attr.attribute}>
                            <td className={styles.attributeColumn}>{loadingAttributes ? <ClipLoader size={20} /> : attr.attribute}</td>
                            <td className={styles.smallColumn} style={{ color: getColorForPercentage(attr.percentage) }}>
                                {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                            </td>
                            <td className={styles.smallColumn}>
                                {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                            </td>
                            <td className={styles.frequentColumn}>
                                {loadingAttributes ? <ClipLoader size={20} /> : (
                                    <>
                                        {attr.topValue.length > 50 ? `${attr.topValue.substring(0, 50)}...` : attr.topValue} ({attr.topValueCount})
                                        {attr.uniqueValuesCount > 1 && (
                                            <button onClick={() => openPopup(attr.attribute)} className={styles.moreButton}>
                                                <FaSearch />
                                            </button>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))
                }

                {attributeView === 'custom' &&
                    sortData(
                        customSelectedAttributes.map(attr => ({
                            attribute: attr.value,
                            label: attr.label,
                            percentage: attributeAuditResults[attr.value]?.percentage || 0,
                            not_empty: attributeAuditResults[attr.value]?.not_empty || 0,
                            topValue: attributeAuditResults[attr.value]?.top_values?.[0]?.[0] || '',
                            topValueCount: attributeAuditResults[attr.value]?.top_values?.[0]?.[1] || 0,
                            uniqueValuesCount: attributeAuditResults[attr.value]?.top_values?.length || 0,
                        }))
                    ).map(attr => (
                        <tr key={attr.attribute}>
                            <td className={styles.attributeColumn}>{loadingAttributes ? <ClipLoader size={20} /> : attr.label}</td>
                            <td className={styles.smallColumn} style={{ color: getColorForPercentage(attr.percentage) }}>
                                {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                            </td>
                            <td className={styles.smallColumn}>
                                {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                            </td>
                            <td className={styles.frequentColumn}>
                                {loadingAttributes ? <ClipLoader size={20} /> : (
                                    <>
                                        {attr.topValue.length > 50 ? `${attr.topValue.substring(0, 50)}...` : attr.topValue} ({attr.topValueCount})
                                        {attr.uniqueValuesCount > 1 && (
                                            <button onClick={() => openPopup(attr.attribute)} className={styles.moreButton}>
                                                <FaSearch />
                                            </button>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>

        {attributeView === 'merchant_center' && (
            <div className={styles.additionalAttributes}>
                <table className={styles.attributeAuditTable}>
                    <thead>
                        <tr>
                            <th className={styles.attributeColumn} onClick={() => handleSort('attribute')}>
                                Additional Attributes from feed {sortConfig.key === 'attribute' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className={styles.smallColumn} onClick={() => handleSort('percentage')}>
                                Coverage % {sortConfig.key === 'percentage' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className={styles.smallColumn} onClick={() => handleSort('not_empty')}>
                                Count # {sortConfig.key === 'not_empty' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className={styles.frequentColumn} onClick={() => handleSort('topValue')}>
                                Most Frequent (Sample) {sortConfig.key === 'topValue' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortData(getDefaultSortedData(
                            Object.keys(attributeAuditResults).filter(attr => !merchantCenterOrder.includes(attr)).map((attribute) => {
                                const topValues = attributeAuditResults[attribute]?.top_values || [];
                                const uniqueValuesCount = topValues.length;
                                return {
                                    attribute,
                                    percentage: attributeAuditResults[attribute]?.percentage,
                                    not_empty: attributeAuditResults[attribute]?.not_empty,
                                    topValue: topValues.length > 0 ? topValues[0][0] : '',
                                    topValueCount: topValues.length > 0 ? topValues[0][1] : 0,
                                    uniqueValuesCount,
                                };
                            })
                        )).map((attr) => (
                            <tr key={attr.attribute}>
                                <td className={styles.attributeColumn}>{loadingAttributes ? <ClipLoader size={20} /> : attr.attribute}</td>
                                <td className={styles.smallColumn} style={{ color: getColorForPercentage(attr.percentage) }}>
                                    {loadingAttributes ? <ClipLoader size={20} /> : `${attr.percentage}%`}
                                </td>
                                <td className={styles.smallColumn}>
                                    {loadingAttributes ? <ClipLoader size={20} /> : attr.not_empty}
                                </td>
                                <td className={styles.frequentColumn}>
                                    {loadingAttributes ? <ClipLoader size={20} /> : (
                                        <>
                                            {attr.topValue.length > 50 ? `${attr.topValue.substring(0, 50)}...` : attr.topValue} ({attr.topValueCount})
                                            {attr.uniqueValuesCount > 1 && (
                                                <button onClick={() => openPopup(attr.attribute)} className={styles.moreButton}>
                                                    <FaSearch />
                                                </button>
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )}
    </div>
)}




            {popupData && (
                    <div className={styles.popupOverlay} id="popupOverlay">
                      <div className={styles.popupContent} id="popupContent">
                        <button onClick={() => setPopupData(null)} className={styles.closeIconButton}>
                          <FaTimes />
                        </button>
                  <h3>Top {popupData.topValues.length} Values for "{popupData.attribute}"</h3>
                  
                  {/* Only show the paragraph if the attribute is 'description' or contains links */}
                  {(popupData.attribute === "description" || popupData.topValues.some((value) => value[0].includes("http"))) && (
                    <p>Please be aware that only the top 10 links, images, videos, and descriptions are displayed.</p>
                  )}

                  <table className={styles.popupTable}>
                    <thead>
                      <tr>
                        <th>{popupData.attribute}</th>
                        <th>Count #</th>
                      </tr>
                    </thead>
                    <tbody>
                      {popupData.topValues.map((value, index) => (
                        <tr key={index}>
                          <td>{value[0]}</td>
                          <td>{value[1]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
{randomSamplePopupData && (
  <div className={styles.popupOverlay} id="randomSamplePopupOverlay">
    <div className={styles.popupContent} id="randomSamplePopupContent">
      <button onClick={() => setRandomSamplePopupData(null)} className={styles.closeIconButton}>
        <FaTimes />
      </button>
      <h3>20 Random {randomSamplePopupData.attribute}</h3>
      <table className={styles.popupTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>{randomSamplePopupData.type === 'title' ? 'titles' : 'descriptions'}</th> {/* Use `randomSamplePopupData.type` */}
          </tr>
        </thead>
        <tbody>
          {randomSamplePopupData.topValues.map((value, index) => (
            <tr key={index}>
              <td>{value.id}</td>
              <td>{value.title || value.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)}





          </>
        )}
      </div>
    </div>
  );
}

export default FeedAudit;
