import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../css/LoginPage.module.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function LoginPage() {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLoginSuccess = async (credentialResponse) => {
    console.log('Login successful, processing token...');
    try {
      localStorage.removeItem('currentFeedId');
      localStorage.removeItem('selectedColumns');

      const response = await axios.post(`${API_BASE_URL}/api/google-login`, {
        token: credentialResponse.credential,
      });

      if (response.data.success) {
        console.log('Token received from API:', response.data.token);
        localStorage.setItem('token', response.data.token);
        console.log('Token stored in localStorage');
        setTimeout(() => {
          console.log('Redirecting to /feedviewer...');
          navigate('/feedviewer');
        }, 100);
      } else {
        console.log('API response unsuccessful:', response.data.message);
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Login failed. Please try again.');
    }
  };

  // Function to clear local storage
  const clearLocalStorage = () => {
    console.log('Clearing all local storage keys...');
    localStorage.clear();
    setErrorMessage('Local storage cleared. Please try logging in again.');
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.headerSubtitle}>s360 Feed Viewer & Audit</h2>
        <p className={styles.infoText}>
          All s360 emails have access to this tool.
        </p>
        <div className={styles.googleButtonContainer}>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => {
              setErrorMessage('Login failed. Please try again.');
              console.log('Google Login failed');
            }}
            className={styles.googleButton}
          />
        </div>
        {errorMessage && (
          <p className={styles.error}>{errorMessage}</p>
        )}
        {/* Button to clear local storage */}
        <button onClick={clearLocalStorage} className={styles.clearButton}>
          Clear Local Storage
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
